:root {
  --dark-background-color: #121212;
}

html, body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: var(--dark-background-color);
  font-family: 'Roboto', sans-serif;
}

#map {
  width: 100vw;
  height: 100vh;
  background-color: var(--dark-background-color);
  cursor: crosshair;
}